<template>
  <div class="contest_wrap curator creator_top">
    <div class="contest_title">Hot Creator</div>
    <swiper class="tc_swiper" ref="swiper" :options="swiperOption">
      <swiper-slide class="tc_swiper_slide">
        <div class="curator_icon">Curator</div>
        <img  class="tc_swiper_slide_pic" src="@/assets/image/main/no_profile_back.jpg" alt="">
        <div class="tc_profile">
          <div class="tc_name">
            <img  class="tc_profile_pic2" src="@/assets/image/main/no_profile_image.png" alt="">
            <div class="tc_nickname">jwo9500</div>
            <img  class="tc_special" src="@/assets/image/main/special.png" alt="">
          </div>
        </div>
        <div class="tc_curator_detail">
          <div class="sub_wrap">
            <img class="icon heart" src="@/assets/image/main/heart.png" alt="">
            <div class="like_cnt">251</div>
            <span class="sub_point">·</span>
            <div class="item_subject">item <span>12123</span></div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="swiper_btn swiper-button-prev" slot="button-prev"></div>
    <div class="swiper_btn swiper-button-next" slot="button-next"></div>
  </div>
</template>

<script>
  import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
  import 'swiper/css/swiper.css'
  export default {
    name: "MemberListTopCuratorLayout",
    mixins: [],
    components: {
      Swiper,
      SwiperSlide
    },
    inject: [],
    provide() {
      return{

      }
    },
    props: {
    },
    data() {
      return{
        swiperOption: {
          slidesPerView: 5,
          spaceBetween: 20,
          centeredSlides:true,
          loop: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        }
      }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{

    },
    watch: {

    },
  }
</script>

<style scoped>

</style>